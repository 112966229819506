<template>
  <div :class="date_width_class">
    <datepicker
      :format="format"
      @selected="passSelectedDate($event)"
      :value="datePreset"
      :placeholder="place_holder"
      :disabled="disabled"
      :disabled-dates="disabledDates"
      :id="id"
    ></datepicker>
  </div>
</template>

<script>
/* eslint-disable */
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'app-date-picker',
  components: {
    Datepicker,
  },
  props: {
    datePreset: {
      type: Date,
      default: null,
    },
    place_holder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    date_width_class: {
      type: String,
      default: 'w30pct',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDateFrom: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      selectedDate: null,
      format: 'MM/dd/yyyy',
      disabledFn: {
        customPredictor(date) {
          if (date.getDate() % 3 === 0) {
            return true
          }
        },
      },
      highlightedFn: {
        customPredictor(date) {
          if (date.getDate() % 4 === 0) {
            return true
          }
        },
      },
      highlighted: {},
      eventMsg: null,
      language: 'en',
      vModelExample: null,
      changedMonthLog: [],
    }
  },
  methods: {
    passSelectedDate(evt) {
      this.$emit('dateSelected', evt)
    },
    highlightTo(val) {
      if (!this.highlighted.to) {
        this.highlighted = {
          to: null,
          daysOfMonth: this.highlighted.daysOfMonth,
          from: this.highlighted.from,
        }
      }
      this.highlighted.to = val
    },
    highlightFrom(val) {
      if (!this.highlighted.from) {
        this.highlighted = {
          to: this.highlighted.to,
          daysOfMonth: this.highlighted.daysOfMonth,
          from: null,
        }
      }
      this.highlighted.from = val
    },
    setHighlightedDays(elem) {
      if (!elem.target.value) {
        return
      }
      let highlightedDays = elem.target.value.split(',').map((day) => parseInt(day))
      this.highlighted = {
        from: this.highlighted.from,
        to: this.highlighted.to,
        daysOfMonth: highlightedDays,
      }
    },
    setDisabledDays(elem) {
      if (!elem.target.value) {
        return
      }
      let disabledDays = elem.target.value.split(',').map((day) => parseInt(day))
      this.disabledDates = {
        from: this.disabledDates.from,
        to: this.disabledDates.to,
        daysOfMonth: disabledDays,
      }
    },
    disableTo(val) {
      if (!this.disabled.to) {
        this.disabledDates = {
          to: null,
          daysOfMonth: this.disabledDates.daysOfMonth,
          from: this.disabled.from,
        }
      }
      this.disabledDates.to = val
    },
    disableFrom(val) {
      if (!this.disabledDates.from) {
        this.disabled = {
          to: this.disabledDates.to,
          daysOfMonth: this.disabled.daysOfMonth,
          from: null,
        }
      }
      this.disabledDates.from = val
    },
    openPicker() {
      this.$refs.programaticOpen.showCalendar()
    },
    logChangedMonth(date) {
      this.changedMonthLog.push(date)
    },
  },
  computed: {
    disabledDates() {
      return { from: this.disabledDateFrom }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
input,
select {
  padding: 0.75em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}

.w30pct {
  width: 30%;
}
.w100pct {
  width: 100%;
}
select {
  height: 2.5em;
}

.example {
  background: #f2f2f2;
  border: 1px solid #ddd;
  padding: 0em 1em 1em;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

h5 {
  font-size: 100%;
  padding: 0;
}

.form-group {
  margin-bottom: 1em;
}

.form-group label {
  font-size: 80%;
  display: block;
}
</style>